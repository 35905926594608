/**
 * @Author: admin 幼发拉底娃娃鱼
 * @Date: 2020-09-06
 */
const md5 = require('md5')

export const globalMixins = {
  data(){
    return {
      pageLoading: null,
    }
  },
  methods: {
    $webAdapter(name) {
      if (window.webAdapter && window.webAdapter[name]) {
        return window.webAdapter[name]
      }
    },
    async $_api(url, method, data, params) {
      let _data, _params;
      if (data){
        _data = this.$getSign(data)
      }
      if (params){
        _params = this.$getSign(params)
      }
      return this.$api({
        url, method, data: _data, params: _params
      });
    },
    $pageLoadingOpen(){
      this.pageLoading = this.$loading({
        lock: true,
        text: '获取数据中...',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, .7)',

      });
    },
    $pageLoadingClose(){
      this.pageLoading.close();
    },
    $go(){

    },
    $getSign(query){
      let _sig = []
      Object.keys(query).forEach(v=>{
        _sig.push(`${v}=${query[v]}`)
      })
      return {
        ...query, signature: md5(_sig.sort().join('&') + 'dwartSignPrivatekey-2020year')
      }
    }
  },
  watch:{
  }
}
