/**
 * @Author: admin 幼发拉底娃娃鱼
 * @Date: 2020-09-08
 */
import axios from 'axios'
import { Message  } from 'element-ui';





axios.defaults.withCredentials = false;
axios.defaults.timeout = 0
axios.defaults.headers = {
  "Accept": 'application/json', 'Content-Type': 'application/json',
}

let baseUrl;

const DEV_BASE_API = "http://39.99.242.100:1663"
// const DEV_BASE_API = "http://192.168.20.211:1663"
// const DEV_BASE_API = "http://192.168.20.21:1663" //陶千禧
// const DEV_BASE_API = "http://192.168.20.118:1663"
const TEST_API = "http://39.99.242.100:1663"

const PROD_BASE_API = "http://student.xweiart.com"
// const PROD_BASE_API = "http://39.99.242.100:1663"

if (process.env.NODE_ENV === 'development') {
  baseUrl = DEV_BASE_API
} else if (process.env.NODE_ENV === 'production') {
  if (process.env.VUE_APP_TITLE === 'test'){
    baseUrl = TEST_API
  }else if (process.env.VUE_APP_TITLE === 'build'){
    baseUrl = PROD_BASE_API
  }
}

const service = axios.create({
  baseURL: baseUrl
})


service.interceptors.request.use(r=>{
  r.headers.agentType = 'pc';
  if (r.url === '/online/user/user/userLogin' || r.url === '/online/user/user/phoneLogin' || r.url === '/online/user/user/phoneGetCode'){
    return  r
  }
  r.headers.Authorization = JSON.parse(localStorage.getItem('login-info')).token
  // r.headers.agentType = 'hwl';


  return r;
}, error => {
  return  Promise.reject(error)
})

service.interceptors.response.use(r=>{
  const res = r.data
  // alert(JSON.stringify(res))
  if (typeof res === 'object'){
    if (res.code == 405){
      console.log(r);
      const oldToken = r.config.headers.Authorization
      axios.post(`${baseUrl}/online/user/register/extraTime`, {
        token: oldToken
      }, {
        headers: {
          agentType: 'pc'
        }
      }).then(nedata => {
        if (nedata.status === 200 && nedata.data.success){
          let o = JSON.parse(localStorage.getItem('login-info'))
          o.token = nedata.data.data
          localStorage.setItem('login-info', JSON.stringify(o))
          location.reload();
        }else{
          location.reload();
        }

      })

    }
    else if (res.message !== null && res.message.length > 0) {
      Message.error(res.message)
      return false;
    }
    else if (res.success){
      return res.data
    }
  }else{
    return res
  }
})

export default service;
