<template>
  <transition name="route">
    <router-view/>
  </transition>
</template>

<script>
export default {
name: "route"
}
</script>

<style lang="scss" scoped>
.router-enter,
.router-leave-to {
  opacity: 0;
  transform: translate3d(-1000px, 600px, -500px) scale3d(0.8, 0.2, 0.7);
}

.router-enter-active {
  transition: all 1s ease;
}

.router-leave-active {
  transition: all 0s ease;
}

</style>
