import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: ()=>import('../views/Index.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: ()=>import('../views/Login')
  },
  {
    path: '/live',
    name: 'Live',
    component: ()=>import('../views/Live')
  },
  {
    path: '/record',
    name: 'Record',
    component: ()=>import('../views/Record')
  },
  {
    path:'/live-pre',
    name: 'BeforeClass',
    component: ()=>import('../views/BeforeClass')
  },
  {
    path:'/record-detail',
    name: 'RecordDetail',
    component: ()=>import('../views/RecordDetail')
  },
  {
    path:'/After',
    name: 'After',
    component: ()=>import('../views/After')
  },
  {
    path: '/test',
    name: 'test',
    component: ()=>import('../views/Test')
  },
  {
    path: '/MyTask',
    name: 'MyTask',
    component: ()=>import('../views/MyTask')
  },
  {
    path: '/SubmitAssignments',
    name: 'SubmitAssignments',
    component: ()=>import('../views/SubmitAssignments')
  }
]

const router = new VueRouter({
  routes
})

export default router
