import Vue from 'vue'
import App from './App.vue'


import router from './router'
import store from './store'
import './registerServiceWorker'
import {globalMixins} from './utils/globalMixins'
import ElementUI from 'element-ui'
import {default as api} from './utils/api'
import JsEncrypt from 'jsencrypt'
import './haoweilai/WeClassRoom-teacher-api-sdk'
import Viewer from 'v-viewer';


import 'normalize.css/normalize.css'
import './_main.scss'
import './utils/e-v.scss'
import 'element-ui/lib/theme-chalk/index.css'
import 'viewerjs/dist/viewer.css'

Vue.use(ElementUI)
Vue.config.productionTip = false
Vue.mixin(globalMixins)
Vue.prototype.$api = api

Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 99999
  }
});

Vue.prototype.$jsEncrypt = function (password) {
  let encrypt = new JsEncrypt();
  encrypt.setPublicKey(
    `-----BEGIN PUBLIC KEY-----
      MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCoZhB+bWaBwuuGTtxpMs2xqoyiFRCB5Ou0pvUtZeePAMDra0UNMqumfyrXPL/bmO8hh9q87+Bg7R0d1qFZiniK7W3SPkTaNyqWKGkUZuCyVUu6gK/5+K3RItRUC+he6yp7kDvZv3j73ZEmWQVqNX0UQahk69bxbt7K11q1SYWrzQIDAQAB
    -----END PUBLIC KEY-----`
  );
  return encrypt.encrypt(password);
};


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
